import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { fluidSizing } from 'src/utils';
import { createTextStyle } from 'src/utils/createTextStyle';
import { OxContainer } from 'src/components/OxContainer';

type TSharedProps = {
    headingFontSize?: string;
};

export const Container = styled(OxContainer)(({ theme }) => css``);

export const BlockText = styled.div(
    ({ theme, headingFontSize }: TSharedProps) => css`
        display: block;
        ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}

        ${headingFontSize &&
        headingFontSize == 'h1' &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 32], [22, 32, 38])}
        `}

        ${headingFontSize &&
        headingFontSize == 'h2' &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 28], [22, 32, 38])}
        `}

            ${headingFontSize &&
        headingFontSize == 'h3' &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 24], [22, 32, 38])}
        `}

        ${headingFontSize &&
        headingFontSize == 'h4' &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 22], [22, 32, 38])}
        `}

        ${headingFontSize &&
        headingFontSize == 'h5' &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 18], [22, 32, 38])}
        `}

        ${headingFontSize &&
        headingFontSize == 'h6' &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 16], [22, 32, 38])}
        `}
    `
);

export const AccordionContainer = styled.div(
    ({ theme }: TSharedProps) => css`
        border-top: 1px solid ${theme.colors.basic.doveGrey};
        ${fluidSizing([{ prop: 'padding-top', values: [10, 10, 10] }])};
    `
);

export const AccordionDetails = styled.details(
    ({ theme }: TSharedProps) => css`
        border-bottom: 1px solid ${theme.colors.basic.doveGrey};
        ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])};

        &::details-content {
            opacity: 0;
            block-size: 0;
            overflow-y: clip;
            transition: content-visibility 1s allow-discrete, opacity 1s, block-size 1s;
        }

        &[open]::details-content {
            opacity: 1;
            block-size: auto;
        }
    `
);

export const AccordionSummary = styled.summary(
    ({ theme }) => css`
        cursor: pointer;
        position: relative;
        text-transform: uppercase;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [10, 10, 10], [14, 14, 14])}
        ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])};
        ${fluidSizing([{ prop: 'padding-right', values: [15, 15, 20] }])};
    `
);

export const AccordionContent = styled.summary(
    ({ theme }) => css`
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [10, 10, 10], [14, 14, 14])}
        ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])};
    `
);

export const AccordionClosed = styled.span(
    ({ theme }) => css`
        position: absolute;
        right: 0;
        top: -2px;

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [14, 14, 14], [14, 14, 14])}

        [open] & {
            display: none;
        }
    `
);

export const AccordionOpened = styled.span(
    ({ theme }) => css`
        display: none;
        position: absolute;
        right: 0;
        top: -2px;

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [14, 14, 14], [14, 14, 14])}

        [open] & {
            display: block;
        }
    `
);
