import * as Styled from './OxAccordion.styled';

import React, { useEffect, useState } from 'react';

import { OxTextParagraph } from 'src/components/OxTextParagraph';
import { PortableText } from '@portabletext/react';
import { OxTextTagline } from 'src/components/OxTextTagline';
import { OxTextHeading } from 'src/components/OxTextHeading';

import {
    OxAccordionItem,
    TAccordionItem
} from 'src/components/OxAccordion/components/OxAccordionItem';

type TProps = {
    tagline: string;
    heading: string;
    headingFontSize: string;
    descriptionBlocks: any;
    openingMultipleItems: boolean;
    items: TAccordionItem[];
};

const intervalTimeout = 5000;
let interval: NodeJS.Timeout;

export const OxAccordion = ({
    tagline,
    heading,
    headingFontSize,
    descriptionBlocks,
    openingMultipleItems,
    items = []
}: TProps): JSX.Element => {
    const [componentIsReady, setComponentIsReady] = useState(false);
    const [activeSlide, setActiveSlide] = useState<number>(0);

    useEffect(() => {
        if (typeof interval !== 'undefined') clearInterval(interval);

        interval = setInterval(() => {
            setActiveSlide((prev) => prev + 1);
        }, intervalTimeout);
    }, [componentIsReady]);

    useEffect(() => {
        let unmounted = false;

        setTimeout(() => {
            !unmounted && setComponentIsReady(true);
        }, 1000);

        return () => {
            unmounted = true;
            !!interval && clearInterval(interval);
        };
    }, []);

    return (
        <Styled.Container>
            <OxTextTagline>{tagline}</OxTextTagline>
            {/* <Styled.BlockText headingFontSize={headingFontSize}>{heading}</Styled.BlockText> */}
            <OxTextHeading>{heading}</OxTextHeading>
            <OxTextParagraph>
                <PortableText value={descriptionBlocks} />
            </OxTextParagraph>

            <Styled.AccordionContainer>
                {items.length > 0 &&
                    items.map((item, index) => (
                        <OxAccordionItem
                            {...item}
                            componentIsReady={componentIsReady}
                            openingMultipleItems={openingMultipleItems}
                            key={index}
                        />
                    ))}
            </Styled.AccordionContainer>
        </Styled.Container>
    );
};
