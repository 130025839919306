import React, { useContext, useRef } from 'react';
import * as Styled from 'src/components/OxAccordion/OxAccordion.styled';
import { PortableText } from '@portabletext/react';

export type TAccordionItem = {
    title: string;
    descriptionBlocks?: any;
    openingMultipleItems: boolean;
};

type TProps = TAccordionItem & {
    componentIsReady: boolean;
};

export const OxAccordionItem = ({
    title,
    descriptionBlocks,
    openingMultipleItems
}: TProps): JSX.Element => {
    return (
        <Styled.AccordionDetails name={openingMultipleItems ? 'openingMultipleItems' : ''}>
            <Styled.AccordionSummary>
                {title}
                <Styled.AccordionClosed>+</Styled.AccordionClosed>
                <Styled.AccordionOpened>-</Styled.AccordionOpened>
            </Styled.AccordionSummary>
            <Styled.AccordionContent>
                <PortableText value={descriptionBlocks} />
            </Styled.AccordionContent>
        </Styled.AccordionDetails>
    );
};
